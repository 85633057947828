<template>
  <div class="container">
    <div class="loader" v-if="isLoading">
      <Loading></Loading>
    </div>

    <div v-else-if="!isLoading" class="content">
      <img v-if="isPaymentSuccess" class="icon" src="@/assets/icons/approval.svg" alt="Check-mark" />

      <img v-if="isPaymentFailed" class="icon" src="@/assets/icons/error_wrong.svg" alt="Check-mark" />

      <h3 v-if="isPaymentSuccess">
        {{ $t("Your card has been updated successfully and will be used for your next renewal on ") }}
        <span v-if="getSubscriptionDate">{{ getSubscriptionDate }}</span
        >.
      </h3>

      <h3 v-if="isPaymentFailed">
        {{ $t("Oops Something went wrong we are not able to add your card. Please contact support@noorplay.com") }}
      </h3>

      <button @click="goToHomePage" class="button-primary">
        {{ $t("Go to home page") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../../../../eventBus";
export default {
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  props: {
    cardUpdateStatus: {
      type: Object,
    },
  },

  data() {
    return {
      isPaymentSuccess: false,
      isPaymentFailed: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(["subscriptionList"]),

    getSubscriptionDate() {
      let billingDate = "";

      if (this.subscriptionList && this.subscriptionList.length) {
        this.subscriptionList.forEach((ele) => {
          if (ele.gwprovider == "CHECKOUT") {
            billingDate = ele.nextbilling;
            return;
          }
        });
      }

      return billingDate;
    },
  },

  mounted() {
    this.isLoading = true;

    this.getPaymentStatus();

    eventBus.$on("paymentDetail-response", (response) => {
      if (response.transactionstatus === "SUCCESS") {
        if (this.transactionType === "SUBSCRIPTION") {
          // Success
        } else if (this.transactionType === "PURCHASE") {
          // Not required
        }
        //eventBus.$emit("triggerInitView", payload);
        this.isPaymentSuccess = true;
      } else if (response.transactionstatus === "PENDING") {
        // Pending State
      } else if (response.transactionstatus === "FAILED") {
        this.isPaymentFailed = true;
        // Failed
      }

      this.isLoading = false;
      localStorage.removeItem("cardUpdateFlowState");
    });
  },

  methods: {
    goToHomePage() {
      this.$router.push("/");
    },

    getPaymentStatus() {
      console.log("THIS IS STATUS card cardUpdateStatus", this.cardUpdateStatus);

      let payload = {
        referenceid: this.cardUpdateStatus.referenceid,
      };
      eventBus.$emit("paymentDetails", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_components.scss";

.button-primary {
  width: 25%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .icon {
    width: 50px;
    height: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 50vw;

    h3 {
      text-align: center;
      width: 75%;
      margin: 2.5rem 0;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width:500px) {
  .button-primary {
    width: 75%;
  }
}

@media only screen and (min-width: 501px) and (max-width:1000px) {
  .button-primary {
    width: 50%;
  }
}
</style>
